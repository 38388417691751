import { NgRedux } from "@angular-redux-ivy/store";
import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { IAppState } from "./app.reducer";
import { persistor } from "./app.store";
import { CloudFrontService } from "./shared/services/cloud-front-service.service";
import { AuthService } from "./shared/services/auth/auth.service";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { environment } from "src/environments/environment";
interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = "accounting";
  private userSubscription: Subscription | null = null;
  storageAreaToken: any;
  rehydrated = false;
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private cloudFrontService: CloudFrontService,
    private authService: AuthService,
    private cookieService: CookieService,
    private socialLogin: SocialAuthService
  ) {}

  ngOnInit(): void {
    if (["localhost", "127.0.0.1"].indexOf(location.hostname) === -1) {
      // if (!this.authService.isLoggedIn()) {
        if (this.cookieService.check("accui_access_token")) {
          this.cloudFrontService.getSignedCookies();
        }
      // }
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    let loader = this.renderer.selectRootElement("#splash-screen");
    this.renderer.setStyle(loader, "display", "none");
    persistor.subscribe(() => {
      if (persistor.getState().bootstrapped) {
        this.rehydrated = true;
      }
    });
    // window.addEventListener("storage", this.handleStorageEvent.bind(this));
    // this.checkTokenInCookies();
  }

  isSideNavCollapsed = false;
  screenWidth = 0;

  onToggleSideNav(data: SideNavToggle): void {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }
  private handleStorageEvent(event: StorageEvent) {
    this.checkTokenInCookies();
  }
  private checkTokenInCookies() {
    const token = this.cookieService.get("accui_access_token");
    if (!token) {
      this.authService.logout();
      this.socialLogin.signOut();
    } else {
      try {
        this.storageAreaToken = this.authService.getCurrentUser();
        this.userSubscription = this.authService.currentUserSubject.subscribe(
          (user: any) => {
            if (this.storageAreaToken?.user_id !== user?.user_id) {
              window.location.reload();
            }
          }
        );
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }
  ngOnDestroy(): void {
    this.cloudFrontService.clearTokenRefreshTimer();
    window.removeEventListener("storage", this.handleStorageEvent.bind(this));
    this.userSubscription?.unsubscribe();
  }
}
