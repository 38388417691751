import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private cookieService: CookieService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (
      !req.url.includes("oauth2/sign_in") &&
      !req.url.includes("oauth2/sign_up")
    ) {
      if ((token && this.authService.tokenExpired()) || !token) {
        this.SessionExpired();
      } else if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      if (req.url.includes("invoices.m1dns.com")) {
        req = req.clone({
          setHeaders: {
            Cookie: `CloudFront-Policy=${this.cookieService.get(
              "CloudFront-Policy"
            )};CloudFront-Signature=${this.cookieService.get(
              "CloudFront-Signature"
            )};CloudFront-Key-Pair-Id=${this.cookieService.get(
              "CloudFront-Key-Pair-Id"
            )}`,
          },
          withCredentials: true,
        });
      }
    }
    return next.handle(req);
  }

  private SessionExpired() {
    return this.authService.logout();
  }
}
